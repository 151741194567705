@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #fff;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  /* */
  /* font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px; */
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #fff;
}

input:-moz-placeholder {
  color: #fff;
}

input::-moz-placeholder {
  color: #fff;
}

input:-ms-input-placeholder {
  color: #fff;
}

textarea::-webkit-input-placeholder {
  color: #fff;
}

textarea:-moz-placeholder {
  color: #fff;
}

textarea::-moz-placeholder {
  color: #fff;
}

textarea:-ms-input-placeholder {
  color: #fff;
}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
.txt1 {
  font-size: 13px;
  color: #e5e5e5;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.wrap-login100 {
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;
  background: #9152f8;
  background: -webkit-linear-gradient(#7579ff, #b224ef);
  background: -o-linear-gradient(#7579ff, #b224ef);
  background: -moz-linear-gradient(#7579ff, #b224ef);
  background: linear-gradient(#7579ff, #b224ef);
}

/*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-logo i {
  color: #9152f8;
}

.login100-form-logo {
  font-size: 60px;
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  display: block;
}

/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(255, 255, 255, 0.24);
  margin-bottom: 30px;
}

.input100 {
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px 0 38px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #fff;
}

.focus-input100::after {
  font-family: Material-Design-Iconic-Font;
  font-size: 22px;
  color: transparent;
  /* color: #fff; */

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.focus-input100 i {
  font-size: 22px;
  color: #fff;
  display: block;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0px;
  padding-left: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 5px;
}

.input100:focus+.focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.input100:focus+.focus-input100 i {
  top: -22px;
  font-size: 18px;
}

.input100:focus+.focus-input100::before {
  width: 100%;
}

.has-val.input100+.focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.has-val.input100+.focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 5px;
}

/*==================================================================
  [ Restyle Checkbox ]*/

.contact100-form-checkbox {
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 35px;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-size: 13px;
  color: #fff;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 11px;
  color: transparent;
  font-weight: 900;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #fff;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked+.label-checkbox100::before {
  color: #555555;
}

/*------------------------------------------------------------------
  [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-size: 16px;
  color: #555555;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 100%;
  height: 50px;
  border-radius: 25px;

  background: #9152f8;
  background: -webkit-linear-gradient(#7579ff, #b224ef);
  background: -o-linear-gradient(#7579ff, #b224ef);
  background: -moz-linear-gradient(#7579ff, #b224ef);
  background: linear-gradient(#7579ff, #b224ef);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fff;
}

.login100-form-btn:hover:before {
  opacity: 0;
}

/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 37px 15px;
  }
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/* 
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
  } */

body {
  line-height: 1;
  background: #f8fafc;
}

.main-configration-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 80px 0 0 120px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  width: calc(100% - 14rem);
  height: 80px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  background: #fff;
  z-index: 0;
  padding: 0 2rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.dashboards-cards .card {
  border-radius: 10px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
}

.main-content-wrapper {
  padding: 80px 0 0 14rem;
  background: #f8f9fc;
  width: 100%;
  min-height: 100vh;
}

.bg-cream-100 {
  background-color: #f9f7c4;
}

.bg-primary-100 {
  background-color: #8b5cf6;
}

.color-primary {
  color: #47404f;
}

.text-primary {
  color: #8d5ff6;
}

.custom-box-shadow {
  box-shadow: 0 0 19px -8px rgba(0, 0, 0, 0.35);
}

.sidebar-content-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 14rem;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  z-index: 90;
  height: 100vh;
  overflow-y: scroll;
  background: #9152f8;
  background: -webkit-linear-gradient(#7579ff, #b224ef);
  background: -o-linear-gradient(#7579ff, #b224ef);
  background: -moz-linear-gradient(#7579ff, #b224ef);
  background: linear-gradient(#7579ff, #b224ef);
}

.sidebar-content-wrapper .navigation {
  padding: 0 1rem;
}

/* 
  .sidebar-content-wrapper .navigation li{
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  
  .navigation li .item {
    padding: 15px 0px;
    text-align: left;
    position: relative;
    overflow: hidden;
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .navigation li .item p {
    color: rgb(255, 255, 255);
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
  
  .navigation .nav-item span i {
    font-size: .85rem;
    width: 35px;
  }
  .navigation li a.active p {
    color: rgb(255, 255, 255);
  } */

.sidebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-content-wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.avatar {
  height: 40px;
  width: 40px;
}

.logo {
  padding: 1.2rem 1rem;
  text-align: center;
}

.logo img {
  width: 160px;
  filter: brightness(100);
}

.divider {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0;
}

/* .navigation .dropdown .item {
    justify-content: space-between;
  } */

/* .navigation li .item span {
    display: flex;
    align-items: flex-end;
  } */

/* .navigation li .item .drop-icon {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  } */

/* New CSS */

.nav-item {
  padding: 1rem 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.drop-items {
  background: #fff;
  border-radius: 0.35rem !important;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
  padding: 0.5rem;
  margin-top: 0.6rem;
}

.drop-items h6 {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.drop-items a {
  padding: 0.4rem 1rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
  line-height: 1.2;
}

.drop-items a:hover,
.drop-items a.active {
  background-color: #dddfeb;
  color: #3a3b45;
  font-weight: 600;
}

.drop-item {
  text-decoration: none !important;
}

.nav-item.dropdown .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item .item span {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-item .item span i {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.85rem;
  margin-right: 0.7rem;
}

.nav-item .item span p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.85rem;
}

.nav-item:hover .item span i,
.nav-item:hover .item span p {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}

.nav-item .item a.active span i,
.nav-item .item a.active span p {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}

.nav-item.dropdown .item .drop-icon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.6rem;
}

.table-container {
  width: 100%;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.5rem;
  /* overflow: hidden; */
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-container table {
  width: 100%;
}

.table-container table thead,
.table-container table tbody {
  /* border-left: .25rem solid #4e73df; */
  /* border-right: .25rem solid #4e73df; */
}

.table-container table tr:hover td {
  background: #c9c9c966;
}

.table-container table thead th {
  background: #f8f9fc;
  border: 0;
  white-space: nowrap;
  /* color: #4e73df; */
  color: #000;
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid #e3e6f0;
  text-align: left;
  /* -webkit-box-sizing: content-box; */
  /* box-sizing: content-box; */
}

.table-container table tbody td,
.table-container table tfoot td {
  padding: 15px;
  color: #858796;
  font-size: 14px;
}

.table-container table tbody tr:not(:last-child) td {
  border-bottom: 1px solid #e3e6f0;
}

table {
  border-collapse: collapse;
}

/* .table-container::-webkit-scrollbar {
    display: none;
  } */

.table-containers {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.table-containers::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.table-containers::-webkit-scrollbar-track {
  background: #f8f9fc;
  /* color of the tracking area */
}

.table-containers::-webkit-scrollbar-thumb {
  background-color: #4e73df;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 5px solid #f8f9fc;
  /* creates padding around scroll thumb */
}

.number_link {
  color: #4e73df;
  text-decoration: underline;
  cursor: pointer;
}

h1 {
  font-size: 30px !important;
  font-weight: 700;
  color: #232121;
  display: inline-block;
  line-height: 1.6;
  font-family: 'Poppins', sans-serif;
}

.card {
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex: 0 0 49%;
  flex-direction: column;
  padding: 20px 15px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgb(139 92 250 / 15%);
}

.data-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
}

input {
  background: #fcfcfc;
  height: 35px;
  max-width: 250px;
  width: 100%;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  box-shadow: 0 3px 6px rgb(139 92 250 / 15%);
}

input::placeholder {
  color: #000;
}

input:-webkit-autofill {
  background: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Change the white to any color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.content-wrapper {
  padding-left: 14rem;
  padding-top: 80px;
}

.content-wrapper-inner {
  padding: 1rem;
}




/* header */

.user-profile-dropdown button {
  border-radius: 100% !important;
  overflow: hidden;
  height: 35px;
  width: 35px;
  padding: 0 !important;
  border: none !important;
}

.user-profile-dropdown button::after {
  display: none;
}

.user-profile-dropdown button img {
  object-fit: fill;
  width: 100%;
}



.claim-data-textbox {
  width: 100%;
  word-break: break-all;
  background: #dddfeb;
  border-radius: 4px;
  padding: 10px;
  font-weight: 500;
}


.status-row {
  background: #dddfeb;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 12px;
}

.payment-header input {
  max-width: 500px !important;
}